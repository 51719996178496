import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import CircleArrowIcon from 'assets/CircleArrowIcon';
import { PrimaryButtonStyles } from 'components/GenericStyles/generic.styles';
import { ThemeFonts } from 'styles/constants';
import { FooterContainerFlex, FooterContainerFixed } from './transitionButtons.styles';

interface props {
  overlay?: boolean;
}

const TransitionButtons = (props: props) => {
  const swiper = useSwiper();

  const onConfirmClick = () => {
    swiper.slideNext();
  };

  const buttons = (
    <>
      <PrimaryButtonStyles
        data-testid="map-confirm-drop-off-button"
        margin="0 0 8px 0"
        onClick={() => onConfirmClick()}
      >
        <ThemeFonts.LabelLarge>I can drop off today</ThemeFonts.LabelLarge>
        <CircleArrowIcon />
      </PrimaryButtonStyles>
    </>
  );

  return props.overlay ? (
    <FooterContainerFixed>{buttons}</FooterContainerFixed>
  ) : (
    <FooterContainerFlex>{buttons}</FooterContainerFlex>
  );
};

export default TransitionButtons;
